var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "network:edit";
},"3":function(container,depth0,helpers,partials,data) {
    return "network:new";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " <input type=\"hidden\" name=\"uuid\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.uuid : stack1), depth0))
    + "\"> Edit "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.name : stack1), depth0))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["public"] : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Connect "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.displayNetwork : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    return "The Lounge - ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lockNetwork : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " to "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.name : stack1), depth0))
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return " <div><div class=\"col-sm-12\"><h2>Network settings</h2></div><div class=\"col-sm-3\"><label for=\"connect:name\">Name</label></div><div class=\"col-sm-9\"><input class=\"input\" id=\"connect:name\" name=\"name\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" maxlength=\"100\"></div><div class=\"col-sm-3\"><label for=\"connect:host\">Server</label></div><div class=\"col-sm-6 col-xs-8\"><input class=\"input\" id=\"connect:host\" name=\"host\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.host : stack1), depth0))
    + "\" aria-label=\"Server address\" maxlength=\"255\" required "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.lockNetwork : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "></div><div class=\"col-sm-3 col-xs-4\"><div class=\"port\"><input class=\"input\" type=\"number\" min=\"1\" max=\"65535\" name=\"port\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.port : stack1), depth0))
    + "\" aria-label=\"Server port\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.lockNetwork : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "></div></div><div class=\"clearfix\"></div><div class=\"col-sm-9 col-sm-offset-3\"><label class=\"tls\"><input type=\"checkbox\" name=\"tls\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.tls : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.lockNetwork : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> Use secure connection (TLS)</label></div><div class=\"col-sm-9 col-sm-offset-3\"><label class=\"tls\"><input type=\"checkbox\" name=\"rejectUnauthorized\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.rejectUnauthorized : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.lockNetwork : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> Only allow trusted certificates</label></div><div class=\"clearfix\"></div></div> ";
},"14":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\" ";
},"16":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <div class=\"col-sm-3\"><label for=\"connect:username\">Username</label></div><div class=\"col-sm-9\"><input class=\"input username\" id=\"connect:username\" name=\"username\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" maxlength=\"512\"></div> ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <div class=\"col-sm-3\"><label for=\"connect:commands\">Commands</label></div><div class=\"col-sm-9\"><textarea class=\"input\" id=\"connect:commands\" name=\"commands\" placeholder=\"One raw command per line, each command will be executed on new connection\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.commands : stack1),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</textarea></div><div class=\"col-sm-9 col-sm-offset-3\"><button type=\"submit\" class=\"btn\">Save</button></div> ";
},"21":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <div class=\"col-sm-3\"><label for=\"connect:channels\">Channels</label></div><div class=\"col-sm-9\"><input class=\"input\" id=\"connect:channels\" name=\"join\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.join : stack1), depth0))
    + "\"></div><div class=\"col-sm-9 col-sm-offset-3\"><button type=\"submit\" class=\"btn\">Connect</button></div> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"header\"><button class=\"lt\" aria-label=\"Toggle channel list\"></button></div><form class=\"container\" method=\"post\" action=\"\" data-event=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.uuid : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\"><div class=\"row\"><div class=\"col-sm-12\"><h1 class=\"title\"> "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.uuid : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " </h1></div> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.displayNetwork : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <div class=\"col-sm-12\"><h2>User preferences</h2></div><div class=\"col-sm-3\"><label for=\"connect:nick\">Nick</label></div><div class=\"col-sm-9\"><input class=\"input nick\" id=\"connect:nick\" name=\"nick\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.nick : stack1), depth0))
    + "\" maxlength=\"100\" required></div> "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.useHexIp : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <div class=\"col-sm-3\"><label for=\"connect:password\">Password</label></div><div class=\"col-sm-9 password-container\"><input class=\"input\" id=\"connect:password\" type=\"password\" name=\"password\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.password : stack1), depth0))
    + "\" maxlength=\"512\"> "
    + ((stack1 = container.invokePartial(require("/home/travis/build/thelounge/thelounge/client/views/reveal-password.tpl"),depth0,{"name":"../reveal-password","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " </div><div class=\"col-sm-3\"><label for=\"connect:realname\">Real name</label></div><div class=\"col-sm-9\"><input class=\"input\" id=\"connect:realname\" name=\"realname\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.realname : stack1), depth0))
    + "\" maxlength=\"512\"></div> "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.defaults : depth0)) != null ? stack1.uuid : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + " </div></form>";
},"usePartial":true,"useData":true});